




import LabDeterminingEmpiricalFormula from '../simulations/LabDeterminingEmpiricalFormula.vue';
import LabTask from '@/tasks/components/dynamic-questions/labs/LabTask.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';

export default defineComponent({
  name: 'DeterminingEmpiricalFormulaSIM',
  components: {LabTask, LabDeterminingEmpiricalFormula},
  mixins: [DynamicQuestionMixin()],
});
